@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.image-content-col {
  margin: rem(20) 0;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  align-content: flex-start;
  align-items: flex-start;

  &__inner {
    width: 100%;
    max-width: rem(600);
    padding: 0 rem($padding);
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    align-content: flex-start;
    align-items: flex-start;
  }

  &__left,
  &__right {
    width: 100%;
  }

  &__right {
    margin: rem(20) 0 0 0;
  }

  &--reverse {
    .image-content-col__left {
      order: 2;
      margin: rem(20) 0 0 0;
    }
  }

  &--no-margin {
    margin: 0;
  }

  &--margin-top {
    margin: rem(10) 0 0 0;
  }

  &--margin-bottom {
    margin: 0 0 rem(30) 0;
  }

}


/*
    Medium Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'md')) {

  .image-content-col {

    margin: rem(20) 0;

    &__left,
    &__right {
      width: 48%;
    }

    &__inner {
      justify-content: space-between;
      align-items: center;
      align-content: center;
      max-width: 95%;
    }

    &--reverse {
      .image-content-col__left {
        order: 0;
      }
    }

    &--no-margin {
      margin: 0;
    }

    &--margin-top {
      margin: rem(70) 0 0 0;
    }

    &--margin-bottom {
      margin: 0 0 rem(40) 0;
    }

  }
}

/*
    Large Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'lg')) {

  .animated-component {

    &.active .image-content-col {

      &__right,
      &__left {
        opacity: 1;
        transform: translateX(0);
      }

    }

    .image-content-col {

      &__right,
      &__left {
        opacity: 0;
        transition: transform 0.7s ease, opacity 0.8s ease;
      }

      &__left {
        transform: translateX(-80%);
      }

      &__right {
        transform: translateX(80%);
      }

    }

  }

  .image-content-col {
    margin: rem(20) 0;

    &__left,
    &__right {
      width: 48%;
    }

    &__inner {
      max-width: map-get($container-max-widths, 'lg');
    }

    &--no-margin {
      margin: 0;
    }

    &--margin-top {
      margin: rem(80) 0 0 0;
    }

    &--margin-bottom {
      margin: 0 0 rem(80) 0;
    }

  }
}

/*
    XLarge Screens
*/
@media screen and (min-width: map-get($grid-breakpoints, 'xl')) {
  .image-content-col {
    margin: rem(20) 0;

    &__left,
    &__right {
      width: 48%;
    }

    &__inner {
      max-width: map-get($container-max-widths, 'xl');
    }

    &--no-margin {
      margin: 0;
    }

    &--margin-top {
      margin: rem(90) 0 0 0;
    }

    &--margin-bottom {
      margin: 0 0 rem(90) 0;
    }

  }
}