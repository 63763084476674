@import '@/styles/imports/variables';
@import '@/styles/imports/utils';

.bottom-section {
    display: flex;
    justify-content: center;
    margin: rem(70);

    &__logo {
        text-align: center;
        width: rem(220);
    }
}
